<template>
  <div>
    <el-form label-width="80px" style="width:600px;">
      <el-form-item>
        <el-button type="primary" @click="generateAccount" size="small">生成随机账号</el-button>
      </el-form-item>
      <el-form-item label="用户账号">
        <el-input v-model="setuserfilestel" disabled></el-input>
        <p style="font-size: 12px;color: #ccc;float: left;width: 100%;margin: 0;text-align:left;">
          默认密码为123456
        </p>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="subsetuserfiles" size="mini">确定</el-button>
        <el-button @click="setuserfilestel = ''" size="mini">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      setuserfilestel: "",
    };
  },
  mounted() {},
  methods: {
    generateAccount() {
      var str = "v";
      var a = this.getFullDate(new Date()); //获取当前日期
      var timestamp = new Date().getTime();
      this.setuserfilestel = str + a + timestamp;
    },
    getFullDate(date) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      return year + month + day;
    },
    subsetuserfiles() {
      var _tel = this.setuserfilestel;
      var _pwd = "123456";
      var _targetid = "s36"; //在线医助1，为绑定疾病的客服(接收)
      console.log({
        tel: _tel,
        pwd: _pwd,
        rf: "超级用户admin建档",
      });
      console.log({ tel: _tel, targetId: _targetid });
      this.axios
        .post(
          "/gu/gu_cust_reg",
          this.qs.stringify({
            tel: _tel,
            pwd: _pwd,
            rf: "超级用户admin建档",
          })
        )
        .then((res) => {
          //服务器创建成功
          if (res.data.code == 0) {
            //聊天服务器创建
            this.axios
              .post(
                this.serveurl.serve + "/api/User/add_user",
                this.qs.stringify({ tel: _tel, targetId: _targetid })
              )
              .then((res) => {
                if (res.data.code == 1) {
                  this.$message({
                    type: "success",
                    message: "创建成功",
                  });
                  this.setuserfilestel = '';
                } else if (res.data.code == -2) {
                  this.$alert(
                    "此用户已存在，属于客服：" + res.data.msg,
                    "提醒",
                    {
                      confirmButtonText: "确定",
                    }
                  );
                } else {
                  this.$message({
                    type: "error",
                    message: res.data.msg,
                  });
                }
              });
          } else {
            this.$message({
              type: "error",
              message: res.data.msg,
            });
          }
        });
    },
  },
};
</script>